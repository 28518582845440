import { defineStore } from 'pinia'

export const useBreadcrumb = defineStore('Breadcrumb', {
    state: () => {
        return {
            matched: [], //面包屑地址
        }
    },
    actions: {
        // 初始化面包屑
        init(route) {
            this.matched = route.matched.filter((r) => Object.keys(r.meta).length);
        },

        // 设置面包屑的title
        setMatched(metaTitle) {
            this.matched.length > 0 ? this.matched[0].meta.title = metaTitle : ''
        },
    }
})